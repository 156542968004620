export const ListVcwAdditions = [
    'Pelotte 3D Boven',
    'SPSI EVA Camel Onder',
    'PPSI EVA Camel Onder',
    'ASC EVA Camel 5mm Onder',
    'ASC EVA Camel 10mm Onder',
    'ASC EVA Camel 5mm Onder, los bijleveren',
    'ASC EVA Camel 10mm Onder, los bijleveren',
    'SA1 PPT 3mm Boven',
    'SA1-5 PPT 3mm Boven',
    'SA2-5 PPT 3mm Boven',
    'SA1 PPT 5mm Boven',
    'SA1 Softlux 5mm Boven',
    'SA1-5 PPT 5mm Boven',
    'SA1-5 Softlux 5mm Boven',
    'SA2-5 PPT 5mm Boven',
    'SA2-5 Softlux 5mm Boven'
]
