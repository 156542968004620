export const modelChoices = new Map()
modelChoices.set('male', [
    { displayValue: 'Lissabon (Teenslipper)', key: 'teenslipper' },
    { displayValue: 'Costa (Teenslipper vegan)', key: 'teenslipper_vegan' },
    { displayValue: 'Rio (Tweeband)', key: 'tweeband' },
    { displayValue: 'Vale (Tweeband met hiel)', key: 'tweeband_hiel' },
    { displayValue: 'Lima (Drieband)', key: 'drieband' },
    { displayValue: 'Montanha (Drieband met hiel)', key: 'drieband_hiel' },
    { displayValue: 'Algarve (E\xE9nband)', key: 'eenband' }
])
modelChoices.set('female', [
    { displayValue: 'Porto (Teenslipper)', key: 'teenslipper' },
    { displayValue: 'Amora (Teenslipper vegan)', key: 'teenslipper_vegan' },
    { displayValue: 'Vila Nova (Tweeband)', key: 'tweeband' },
    { displayValue: 'Douro (Tweeband met hiel)', key: 'tweeband_hiel' },
    { displayValue: 'Maia (Drieband)', key: 'drieband' },
    { displayValue: 'Faro (Drieband met hiel)', key: 'drieband_hiel' }
])

export const outsoleChoices = [ // loopzool
    'EVA 10mm zwart grof',
    'EVA 10mm bruin grof',
    'EVA 10mm zwart fijn',
    'EVA 10mm bruin fijn',
    'Creplina beige',
    'Creplina blauw',
    'Creplina wit',
    'Creplina zwart'
]

export const blockChoices = [ // blokkeuze
    'EVA 50sh - 30mm - zwart',
    'EVA 50sh - 30mm - kurk',
    'EVA 50sh - 38mm - zwart',
    'EVA 50sh - 38mm - kurk'
]

export const finishingShapeOptions = [
    'Volledig afgewerkt',
    'Los bijleveren',
    'Afdek tot RCTB vastgeplakt'
]

export const coverMaterial = [
    'Flexline zwart',
    'Gladleder beige',
    'Gladleder blauw',
    'Gladleder bruin',
    'Gladleder cognac',
    'Gladleder grijs',
    'Gladleder zwart',
    'Su\xE8de beige',
    'Su\xE8de blauw',
    'Su\xE8de bruin',
    'Su\xE8de cognac',
    'Su\xE8de grijs',
    'Su\xE8de zwart'
]

export const upperMaterialOptions = new Map()
upperMaterialOptions.set('male', new Map())
upperMaterialOptions.set('female', new Map())

upperMaterialOptions.get('male').set('teenslipper_vegan', [
    'Blauw pucalpa vegan leer',
    'Zwart pucalpa vegan leer'
]).set('teenslipper', [
    'Berg groen leer',
    'Cognac leer',
    'Zwart leer'
]).set('tweeband', [
    'Berg groen leer',
    'Blauw pucalpa vegan leer',
    'Cognac leer',
    'Zwart leer'
]).set('tweeband_hiel', [
    'Cognac leer',
    'Gommato leer',
    'Zwart leer'
]).set('drieband', [
    'Blauw pucalpa vegan leer',
    'Cognac leer',
    'Donkerbruin leer',
    'Zwart leer'
]).set('drieband_hiel', [
    'Blauw pucalpa vegan leer',
    'Cognac leer',
    'Donkerbruin leer',
    'Grijs leer',
    'Zwart leer',
    'Zwart pucalpa vegan leer'
]).set('eenband', [
    'Camel leer',
    'Donkerbruin leer',
    'Zwart leer'
])

upperMaterialOptions.get('female').set('teenslipper_vegan', [
    'Blauw bloem vegan leer',
    'Blauw pucalpa vegan leer',
    'Bordeaux vegan leer',
    'Cognac vegan leer',
    'Wit vegan leer',
    'Zwart bloem vegan leer',
    'Zwart vegan leer'
]).set('teenslipper', [
    'Antraciet leer',
    'Antraciet snake leer',
    'Beige snake nubuck',
    'Blauw bloem vegan leer',
    'Blauw fantasy leer',
    'Blauw glans leer',
    'Blauw leer',
    'Bordeaux vegan leer',
    'Brons leer',
    'Camel leer',
    'Cognac leer',
    'Fantasy circle leer',
    'Forest leaf leer',
    'Fuchsia leer',
    'Goud leer',
    'Groen leer',
    'Koraal leer',
    'Koral fantasy leer',
    'Wit vegan leer',
    'Zalm leer',
    'Zilver print leer',
    'Zwart bloem vegan leer',
    'Zwart glans leer',
    'Zwart glitter',
    'Zwart leer',
    'Zwart panter velvet'
]).set('tweeband', [
    'Antraciet leer',
    'Antraciet snake leer',
    'Beige snake nubuck',
    'Blauw fantasy leer',
    'Blauw leer',
    'Blauw nubuck',
    'Bordeaux vegan leer',
    'Brons leer',
    'Cognac vegan leer',
    'Forest leaf leer',
    'Goud leer',
    'Groen leer',
    'Koraal fantasy leer',
    'Wit vegan leer',
    'Zilver print leer',
    'Zwart glans leer',
    'Zwart leer',
    'Zwart panter velvet'
]).set('tweeband_hiel', [
    'Antraciet leer',
    'Blauw leer',
    'Bordeaux vegan leer',
    'Cognac vegan leer',
    'Wit vegan leer',
    'Zwart leer'
]).set('drieband', [
    'Antraciet leer',
    'Blauw leer',
    'Bordeaux vegan leer',
    'Cognac vegan leer',
    'Donkerbruin leer',
    'Groen leer',
    'Wit vegan leer',
    'Zwart leer',
    'Zwart panter velvet'
]).set('drieband_hiel', [
    'Antraciet leer',
    'Blauw leer',
    'Bordeaux vegan leer',
    'Cognac vegan leer',
    'Donkerbruin leer',
    'Groen leer',
    'Wit vegan leer',
    'Zwart leer',
    'Zwart panter Velvet'
])

export const fitsSize = {
    min: 34,
    max: 48
}
