import moment from 'moment'
import { combineItems, getPrefix } from './util'

let dataprovider = ''

/**
 * Create a JSON export for rhino modeling.
 *
 * @param {Object} state - the full state of the application.
 */
function rhinoExport (state) {
    const patient = state.general.patientData
    const orderType = state.general.orderType
    const diagnosis = state.diagnosis.diagnosisData
    const soleSpec = state.soleSpecification
    const fitsSpec = state.fitsSpecification
    const soleRes = state.soleResult
    const soleAddition = state.soleAddition.additionData
    const patientName = `${patient.lastName}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    dataprovider = state.other.dataProvider

    let rhinoExport = {}
    switch (orderType) {
    case 'fits':
        rhinoExport = {
            sendDate: moment().toISOString(),
            fitsType: dataprovider === 'fits-comfort' ? 'fitsComfortResult' : 'fitsCustomResult',
            general: {
                patient: {
                    name: patientName,
                    sex: patient.gender,
                    birthdate: moment(patient.birthdate).toISOString(),
                    id: getPrefix(state.other.backend) + parseInt(patient.number.replace(/\D/g, ''))
                },
                postponeOrder: patient.postponeOrder,
                therapist: patient.therapist.name,
                fittingLocation: patient.fittingLocation.title,
                receiveLocation: patient.receiveLocation.title
            },
            fitsResult: modelFitsResultRhino(fitsSpec, soleRes, diagnosis)
        }
        break
    default:
        rhinoExport = {
            sendDate: moment().toISOString(),
            general: {
                patient: {
                    name: patientName,
                    sex: patient.gender,
                    birthdate: moment(patient.birthdate).toISOString(),
                    id: getPrefix(state.other.backend) + parseInt(patient.number.replace(/\D/g, ''))
                },
                emergencyOrder: patient.emergencyOrder,
                postponeOrder: patient.postponeOrder,
                therapist: patient.therapist.name,
                fittingLocation: patient.fittingLocation.title,
                receiveLocation: patient.receiveLocation.title,
                completePair: diagnosis.completePair
            },
            soleResult: modelSoleResultRhino(soleSpec, soleRes, diagnosis, soleAddition)
        }
        break
    }

    // if the right side is not modeled, remove it ...
    if (diagnosis.symmetrical && !soleSpec[0].soleData.displayRightSole) {
        if (orderType === 'fits') {
            delete rhinoExport.fitsResult.right
        } else {
            delete rhinoExport.soleResult.right
        }
    }
    return rhinoExport
}

/**
 *
 * @param {*} fitsSpec
 * @param {*} soleRes
 * @param {*} diagnosis
 * @returns
 */
function modelFitsResultRhino (fitsSpec, soleRes, diagnosis) {
    const soleResult = []
    for (let i = 0; i < diagnosis.numberOfSoles; i++) {
        soleResult.push({
            left: {
                footSize: parseFloat(fitsSpec[i].fitsData.footSizeLeft),
                elements: modelSoleResultElements(soleRes[i].soleResultData.left, soleRes[i].soleResultSuggestion.left, fitsSpec[i].fitsData, true)
            },
            right: {
                footSize: parseFloat(fitsSpec[i].fitsData.footSizeRight),
                elements: modelSoleResultElements(diagnosis.symmetrical ? soleRes[i].soleResultData.left : soleRes[i].soleResultData.right, soleRes[i].soleResultSuggestion.right, fitsSpec[i].fitsData, true)
            },
            fitsSpecification: modelFitsSpecifications(fitsSpec[i].fitsData, soleRes[i].soleResultData)
        })
    }

    return soleResult
}

/**
 * Filter values with a for loop, to get only the values entered OR values with a suggestion.
 *
 * @param {Object} soleResInput - sole result inxwput values.
 * @param {Object} soleResSug - sole result suggesiton values.
 * @return {Object} - filtered soleresult elements.
 */
function modelSoleResultElements (soleResInput, soleResSug, spec, isFits) {
    const model = []
    const soleType = spec.soleType === 'podozool' ? 'podo' : 'ortho'
    for (const [key, value] of Object.entries(soleResInput)) {
        // skip if tafelberg, heellift and mictype.
        if (key === 'tafelberg' || key === 'externalHeelLift' || key === 'MICType' || key === 'MICPosition' || key === 'pelotteType') continue

        let suggestedKey = key

        if (key === 'MIC') {
            suggestedKey = `${soleType}${soleResInput.MICType}${soleResInput.MICPosition === 'TMT - 1' ? 'TMT-1' : ''}`
        } else if (key === 'pelotte') {
            if (soleResInput.pelotteType !== 'pelotte' && soleResInput.pelotteType !== '' && soleResInput.pelotteType !== null) {
                suggestedKey = 'pelotteCM' + soleResInput.pelotteType.match(/\d+/g).join('')
            }
        }

        // if fasciPad or ascPad check if the string value is the same as 'true'.
        if (key === 'fasciPad' || key === 'ascPad') {
            model.push({
                name: suggestedKey,
                value: value === 'true'
            })
            continue
        }

        if (isFits && key === 'ASC') {
            let correctValue = parseInt(value) || null
            const tafelberg = parseFloat(soleResInput.tafelberg) || 0
            const extHeel = parseFloat(soleResInput.externalHeelLift) || 0

            if (value === '' || value === null) {
                const suggestionValue = soleResSug[key]
                if (suggestionValue !== '') {
                    // not empty, use it
                    correctValue = parseInt(suggestionValue) || 0
                }
            }

            model.push({
                name: suggestedKey,
                value: calculateHeights(correctValue, tafelberg, extHeel).asc
            })

            model.push({
                name: 'externalHeelLift',
                value: calculateHeights(correctValue, tafelberg, extHeel).extHeel
            })
            continue
        }

        // if there is not an value entered check for an suggestion.
        if (value === '' || value === null) {
            // check the suggestion value
            const suggestionValue = soleResSug[key]
            if (suggestionValue !== '') {
                // not empty, use it
                model.push({
                    name: suggestedKey,
                    value: parseInt(suggestionValue) || 0
                })
            }
            // empty, skip it
            continue
        }

        if (key === 'relief') {
            const combinedItems = combineItems(value)
            combinedItems.forEach(element => {
                model.push({
                    name: `relief${element}`,
                    value: 0 - soleResInput.SA15
                })
            })
        } else {
            model.push({
                name: suggestedKey,
                value: parseInt(value)
            })
        }
    }
    return model
}

/**
 * get json export of fitsspecifications
 *
 * @param {Object} fitsSpecs - sole specification
 * @param {Number} numberOfSoles - amount of modeled soles
 * @returns {Object} with solespecification.
 */
function modelFitsSpecifications (spec) {
    let patternName = ''

    if (dataprovider === 'fits-comfort') {
        patternName = 'FITS Comfort'
    } else {
        if (!spec.model.displayValue) {
            patternName = ''
        } else if (spec.model.displayValue.includes('Teenslipper')) {
            patternName = 'Teenmodel'
        } else {
            patternName = 'Bandenmodel'
        }
    }
    const fitsSpecRhino = {
        model: spec.model,
        patternName,
        upperMaterial: spec.upperMaterial,
        coverMaterial: spec.coverMaterial,
        outsole: spec.outsole,
        finishingShape: spec.finishingShape,
        soleSizeLeft: parseFloat(spec.soleSizeLeft),
        soleSizeRight: parseFloat(spec.soleSizeRight),
        leftSoleWidth: parseFloat(spec.leftSoleWidth),
        leftSoleWidthMessage: spec.leftSoleWidthMessage,
        rightSoleWidth: parseFloat(spec.rightSoleWidth),
        rightSoleWidthMessage: spec.rightSoleWidthMessage,
        hypoallergenicGlue: spec.hypoallergenicGlue
    }

    /*
    * Add fits-comfort variables to the fits rhino export.
    */
    if (dataprovider === 'fits-comfort') {
        fitsSpecRhino.totalLeftSoleThickness = parseFloat(spec.totalLeftSoleThickness)
        fitsSpecRhino.totalRightSoleThickness = parseFloat(spec.totalRightSoleThickness)
        fitsSpecRhino.roughenSole = spec.roughenSole
        fitsSpecRhino.block = spec.block
        fitsSpecRhino.millingSize = spec.millingSize
    } else {
        fitsSpecRhino.block = generateBlock(spec.block).name
        fitsSpecRhino.millingSize = generateBlock(spec.block).height
        fitsSpecRhino.extraElement = (spec.extraElement.includes('30sh 3mm EVA - zwart') ? '30sh 3mm EVA - zwart' : false)
    }
    return fitsSpecRhino
}

/**
 * Generate a block object with a string
 *
 * @param {String} block - block name
 * @return {object} block object with height and name
 */
function generateBlock (block) {
    const objBlock = {}
    if (block.includes('38mm hoog')) {
        objBlock.height = '38 mm'
        objBlock.name = block.replace(' - 38mm hoog', '')
    } else if (block.includes(' - 38mm -')) {
        objBlock.height = '38 mm'
        objBlock.name = block.replace(' - 38mm', '')
    } else if (block.includes(' - 30mm -')) {
        objBlock.height = '30 mm'
        objBlock.name = block.replace(' - 30mm', '')
    } else {
        objBlock.height = '30 mm'
        objBlock.name = block
    }

    return objBlock
}

/**
 *
 * @param {*} soleSpec
 * @param {*} soleRes
 * @param {*} diagnosis
 * @returns
 */
function modelSoleResultRhino (soleSpec, soleRes, diagnosis, soleAdd) {
    const soleResult = []
    for (let i = 0; i < diagnosis.numberOfSoles; i++) {
        soleResult.push({
            left: diagnosis.completePair === 'right'
                ? {}
                : {
                    footSize: parseFloat(soleSpec[i].soleData.footSizeLeft),
                    elements: modelSoleResultElements(soleRes[i].soleResultData.left, soleRes[i].soleResultSuggestion.left, soleSpec[i].soleData, false)
                },
            right: diagnosis.completePair === 'left'
                ? {}
                : {
                    footSize: parseFloat(soleSpec[i].soleData.footSizeRight),
                    elements: modelSoleResultElements(diagnosis.symmetrical ? soleRes[i].soleResultData.left : soleRes[i].soleResultData.right, soleRes[i].soleResultSuggestion.right, soleSpec[i].soleData, false)
                },
            soleSpecification: modelSoleSpecifications(soleSpec[i].soleData),
            soleAdditions: modelSoleAdditions(soleAdd.addition, i)
        })
    }

    return soleResult
}

/**
 *
 * @param {*} soleAdd
 * @param {*} number
 * @returns
 */
function modelSoleAdditions (soleAdd, number) {
    const soleAddArr = []
    soleAdd.forEach((element) => {
        if (element.forSoles.indexOf(number) > -1) {
            soleAddArr.push({ name: element.name, location: element.location })
        }
    })
    return soleAddArr
}

/**
 * get json export of solespecifications
 *
 * @param {Object} soleSpecs - sole specification
 * @param {Number} numberOfSoles - amount of modeled soles
 * @returns {Object} with solespecification.
 */
function modelSoleSpecifications (spec) {
    return {
        millingSize: parseInt(spec.millingSize),
        block: generateBlock(spec.block.key).name,
        groundSole: spec.groundSole.name,
        soleSizeLeft: parseFloat(spec.soleSizeLeft),
        soleSizeRight: parseFloat(spec.soleSizeRight),
        broadenedFront: spec.broadenedFront === '' || spec.broadenedFront === null ? 0 : parseInt(spec.broadenedFront),
        coverMaterial: spec.coverMaterial,
        coverLevel: spec.coverMaterial === '-' ? '-' : spec.coverLevel,
        totalLeftSoleThickness: parseFloat(spec.totalLeftSoleThickness),
        totalRightSoleThickness: parseFloat(spec.totalRightSoleThickness),
        soleCompleteness: spec.soleCompleteness,
        narrowGrinding: false,
        hypoallergenicGlue: spec.hypoallergenicGlue
    }
}

/**
 *
 * @param {*} asc
 */
function calculateHeights (ASC, tafelberg, externalHeelLift) {
    const minASC = 6 // minimale ASC hoogte voor FITS custom
    const minExtHeel = 2 // minimale waarde voor de externe heel lift die in PLTLife ingevuld wordt
    let extHeel
    let asc

    if (tafelberg > 0) {
        extHeel = tafelberg
        asc = ASC - extHeel

        if (externalHeelLift < minExtHeel) {
            asc = ASC - (externalHeelLift - minExtHeel)
            extHeel = minExtHeel
        }

        if (ASC < minASC) {
            extHeel = tafelberg + (minASC - ASC)
            asc = minASC
        }
    } else {
        asc = ASC
        extHeel = externalHeelLift
    }

    return { asc, extHeel }
}

export { rhinoExport }
