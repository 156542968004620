export const modelChoices = new Map()
modelChoices.set('male', [
    { displayValue: 'Gesloten hiel (Unisex)', key: 'gesloten_hiel' },
    { displayValue: 'Open hiel (Unisex)', key: 'open_hiel' }
])
modelChoices.set('female', [
    { displayValue: 'Gesloten hiel (Unisex)', key: 'gesloten_hiel' },
    { displayValue: 'Open hiel (Unisex)', key: 'open_hiel' }
])

export const blockChoices = new Map()
blockChoices.set(30, [ // blokkeuze
    'EVA 50sh',
    'Combi 30sh',
    'Combi 40sh',
    'Combi sport 35sh',
    'Supraflex 30sh',
    'Supraflex 40sh',
    'Supraflex 50sh'
])
blockChoices.set(38, ['EVA 50sh'])

export const finishingShapeOptions = [
    'Volledig afdekken',
    'Los bijleveren'
]

export const coverMaterial = [
    'Su\xE8de beige',
    'Su\xE8de bruin',
    'Su\xE8de grijs',
    'Su\xE8de zwart',
    'Vilt wit',
    'Vilt grijs',
    'Vilt blauw',
    'Vilt zwart',
    'Vilt rood'
]

export const upperMaterialOptions = new Map()
upperMaterialOptions.set('male', new Map())
upperMaterialOptions.set('female', new Map())

upperMaterialOptions.get('male').set('gesloten_hiel', [
    'Blauw',
    'Rood',
    'Zwart'
]).set('open_hiel', [
    'Blauw',
    'Rood',
    'Zwart'
])

upperMaterialOptions.get('female').set('gesloten_hiel', [
    'Blauw',
    'Rood',
    'Zwart'
]).set('open_hiel', [
    'Blauw',
    'Rood',
    'Zwart'
])

export const fitsSize = {
    min: 35,
    max: 49
}

export const widthSizes = [
    { size: 35, min: 72, max: 87 },
    { size: 36, min: 73, max: 88 },
    { size: 37, min: 75, max: 90 },
    { size: 38, min: 77, max: 92 },
    { size: 39, min: 78, max: 93 },
    { size: 40, min: 79, max: 94 },
    { size: 41, min: 80, max: 95 },
    { size: 42, min: 82, max: 97 },
    { size: 43, min: 83, max: 98 },
    { size: 44, min: 84, max: 99 },
    { size: 45, min: 86, max: 101 },
    { size: 46, min: 87, max: 102 },
    { size: 47, min: 89, max: 104 },
    { size: 48, min: 91, max: 106 },
    { size: 49, min: 92, max: 107 }
]

export const unavailable = new Map()
unavailable.set('gesloten_hiel', new Map())
unavailable.get('gesloten_hiel').set('Rood', [
])

unavailable.set('open_hiel', new Map())
unavailable.get('open_hiel').set('Rood', [
])
