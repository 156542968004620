import moment from 'moment'
import pck from '../../package.json'
import { tafelberg } from '../store/reducers/soleResult'

/**
 * Get a full state export
 *
 * @param {Object} state - full state.
 * @returns {Object} full export for Podo-IT
 */
function podoITexport (state) {
    const orderType = state.general.orderType
    const patient = state.general.patientData
    const diagnosis = state.diagnosis.diagnosisData
    const soleSpec = state.soleSpecification
    const fitsSpec = state.fitsSpecification
    const soleRes = state.soleResult
    const soleAdd = state.soleAddition.additionData

    let topOrderType = orderType
    if (orderType === 'fits') {
        if (state.other.dataProvider === 'fits-comfort') {
            topOrderType = 'fitsComfort'
        } else {
            topOrderType = 'fitsCustom'
        }
    }

    const podoExport = orderType === 'fits'
        ? {
            version: pck.version,
            orderType: topOrderType,
            sendDate: moment().toISOString(),
            general: { ...patient },
            diagnosis: { ...diagnosis },
            fitsSpecification: modelFitsSpecificationsPodo(fitsSpec, diagnosis, soleRes, orderType),
            soleResult: soleResultInput(soleRes, diagnosis, fitsSpec, orderType),
            soleResultSuggestion: soleResultSuggestion(soleRes, diagnosis, fitsSpec, orderType)
        }
        : {
            version: pck.version,
            orderType: topOrderType,
            sendDate: moment().toISOString(),
            general: { ...patient },
            diagnosis: { ...diagnosis },
            soleSpecification: modelSoleSpecificationsPodo(soleSpec, diagnosis),
            soleResult: soleResultInput(soleRes, diagnosis, soleSpec, orderType),
            soleResultSuggestion: soleResultSuggestion(soleRes, diagnosis, soleSpec, orderType),
            soleAddition: soleAdd
        }
    return podoExport
}

/**
 * Generate a soleresult export
 *
 * @param {Object} soleResult - sole result to model, can be suggestion or input.
 * @param {Object} diagnosis - sole result to model, can be suggestion or input.
 * @return {Array} list of soleresults(suggestions)
 */
function soleResultInput (soleResult, diagnosis, spec, orderType) {
    const model = []
    for (let i = 0; i < parseInt(diagnosis.numberOfSoles); i++) {
        const specData = orderType === 'fits' ? spec[i].fitsData : spec[i].soleData
        model.push({
            left: modelSoleResultElementsPodo(soleResult[i].soleResultData.left, true, specData, orderType),
            right: modelSoleResultElementsPodo(soleResult[i].soleResultData.right, true, specData, orderType)
        })
    }
    return model
}

/**
 * Generate a soleresult export
 *
 * @param {Object} soleResult - sole result to model, can be suggestion or input.
 * @return {Array} list of soleresults(suggestions)
 */
function soleResultSuggestion (soleResult, diagnosis, spec, orderType) {
    const model = []
    for (let i = 0; i < parseInt(diagnosis.numberOfSoles); i++) {
        const specData = orderType === 'fits' ? spec[i].fitsData : spec[i].soleData
        model.push({
            left: modelSoleResultElementsPodo(soleResult[i].soleResultSuggestion.left, false, specData, orderType),
            right: modelSoleResultElementsPodo(soleResult[i].soleResultSuggestion.right, false, specData, orderType)
        })
    }
    return model
}

/**
 * parse all fields for sole specifications
 *
 * @param {Object} soleSpecs - sole specifications input values.
 * @return {Object} - filtered soleresult elements.
 */
function modelSoleSpecificationsPodo (soleSpecs, diagnosis) {
    const model = []
    for (let i = 0; i < parseInt(diagnosis.numberOfSoles); i++) {
        // get sole data
        const { soleData: spec } = soleSpecs[i]

        // create data from sole data
        model.push({
            millingSize: parseInt(spec.millingSize),
            block: spec.block,
            groundSole: spec.groundSole,
            coverMaterial: spec.coverMaterial,
            coverLevel: spec.coverMaterial === '-' ? '-' : spec.coverLevel,
            soleType: `${soleSpecs[0].soleData.soleType}`,
            displayUKsize: spec.displayUKsize,
            displayRightSole: spec.displayRightSole,
            soleCompleteness: spec.soleCompleteness,
            footSizeLeft: parseFloat(spec.footSizeLeft),
            footSizeRight: parseFloat(spec.footSizeRight),
            soleSizeLeft: parseFloat(spec.soleSizeLeft),
            soleSizeRight: parseFloat(spec.soleSizeRight),
            broadenedFront: spec.broadenedFront === null ? null : parseInt(spec.broadenedFront),
            leftSoleThickness: parseFloat(spec.leftSoleThickness),
            rightSoleThickness: parseFloat(spec.rightSoleThickness),
            totalLeftSoleThickness: parseFloat(spec.totalLeftSoleThickness),
            totalRightSoleThickness: parseFloat(spec.totalRightSoleThickness),
            hypoallergenicGlue: spec.hypoallergenicGlue
        })
    }
    return model
}

/**
 * parse all fields for fits specifications
 *
 * @param {Object} fitsSpecs - fits specifications input values.
 * @return {Object} - filtered soleresult elements.
 */
function modelFitsSpecificationsPodo (fitsSpecs, diagnosis, soleRes) {
    const model = []
    for (let i = 0; i < parseInt(diagnosis.numberOfSoles); i++) {
        // get fits data
        const { fitsData: spec } = fitsSpecs[i]
        const { soleResultData: solRes } = soleRes[i]

        // create data from fits data
        model.push({
            model: spec.model,
            upperMaterial: spec.upperMaterial,
            coverMaterial: spec.coverMaterial,
            block: spec.block,
            millingSize: spec.millingSize,
            outsole: spec.outsole,
            finishingShape: spec.finishingShape,
            footSizeLeft: spec.footSizeLeft,
            footSizeRight: spec.footSizeRight,
            soleSizeLeft: spec.soleSizeLeft,
            soleSizeRight: spec.soleSizeRight,
            leftSoleWidth: parseFloat(spec.leftSoleWidth),
            leftSoleWidthMessage: spec.leftSoleWidthMessage,
            rightSoleWidth: parseFloat(spec.rightSoleWidth),
            rightSoleWidthMessage: spec.rightSoleWidthMessage,
            externalHeelLiftLeft: solRes.left.externalHeelLift,
            externalHeelLiftRight: solRes.right.externalHeelLift,
            extraElement: spec.extraElement,
            soleType: spec.soleType,
            displayRightSole: spec.displayRightSole,
            hypoallergenicGlue: spec.hypoallergenicGlue
        })
    }
    return model
}

/**
 * Filter values with a for loop, to get only the values entered OR values with a suggestion.
 *
 * @param {Object} soleRes - sole result input values.
 * @param {Object} isResInput - sole result suggesiton values.
 * @param {String} orderType - Contains the type of order
 * @return {Object} - filtered soleresult elements.
 */
function modelSoleResultElementsPodo (soleRes, isResInput, specData, orderType) {
    const model = {}
    for (const [key, value] of Object.entries(soleRes)) {
        // tafelberg should be a string.
        if (key === 'tafelberg') {
            model[key] = `${value}`
            continue
        }

        // externalHeelLift should be ignored.
        if (key === 'externalHeelLift') continue

        // if fasciPad or ascPad check if the string value is the same as 'true'.
        if (key === 'fasciPad' || key === 'ascPad' || key === 'MICPosition' || key === 'MICType' || key === 'pelotteType') {
            model[key] = value
            continue
        }

        if (key === 'LFW' || key === 'MFW') {
            model[key] = `${value}`
            continue
        }

        if (key === 'relief') {
            model[key] = value
            continue
        }

        // if FITS has a tafelberg for maximum sole size the ASC will be lowered and included as an externel heel Lift
        if (orderType === 'fits') {
            if (key === 'ASC' && specData.block.includes('38')) {
                model[key] = `${value - Math.ceil(tafelberg)}`
            }
        } else {
            if (key === 'ASC' && specData.block.millingSize === 38) {
                model[key] = `${value - Math.ceil(tafelberg)}`
            }
        }

        // key should not be set when relief is the key.
        if (isResInput) {
            model[key] = value !== '' && value !== null ? parseInt(value) : null
        } else {
            model[key] = `${value}`
        }
    }
    return model
}

export { podoITexport }
